$brown: #593e23;
$footer-brown: #755c3f;

$skyblue: #81bce3;
$skyblue-end: #9fcae6;
$green: #33a85c;

$black: #000000;
$white: #ffffff;

$mentone: 'Mentone', 'Helvetica Neue', Helvetica, Arial, sans-serif;