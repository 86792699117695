main {
  section.splash {
    height: 100vh;
    overflow: hidden;

    @include medium {
      overflow: visible;
      min-height: 35em;
    }

    .container {
      z-index: 5;
      top: 25%;
      height: 50%;

      text-align: center;

      background: $skyblue;
      background: -moz-linear-gradient(bottom, $skyblue 0%, $skyblue-end 100%);
      background: -webkit-linear-gradient(bottom, $skyblue 0%,$skyblue-end 100%);
      background: linear-gradient(to bottom, $skyblue 0%,$skyblue-end 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$skyblue}', endColorstr='#{$skyblue-end}',GradientType=0 );

      @include medium {
        min-height: 25em;
      }
    }

    .logo {
      display: inline-block;
      position: relative;
      top: 40%;
      
      font-size: 0.65em;

      @include medium {
        font-size: 1em;
      }

      h1 {
        font-size: 4.25em;
        line-height: 1.15;
        
        transform: translateY(100%); // animation
      }

      .logo-text {
        overflow: hidden;
      }

      .underline {
        width: 0; //98%;
        height: 0.5em;
        margin-left: 0.5%;

        background: $green;
      }
    }

    .circle-container {
      width: 100%;
      position: absolute;
      bottom: -8.5em;
      left: -0.5em;

      @include medium {
        bottom: -10em;
      }

      .circles-left {
        .circle-visual {
          background: $skyblue-end;
        }
      }

      .circles-right {
        position: absolute;
        right: -1em;
        top: -5.5em;

        @include medium {
          top: -10.5em;
        }

        ul.circles {
          text-align: right;
        }
      }
    }
  }

  section.about {
    position: relative;

    .mask {
      position: relative;
      color: $white;

      width: 100%;
      padding: 0 2em 4em;

      @include medium {
        width: 80%;
        padding: 0 5em 4em 0;
      }

      @include large { 
        max-width: 38em;
      }

      .background, &:after {
        position: absolute;
        width: 300%;
        left: -200%;

        z-index: 2;
        background: $black;
      }

      .background {
        transform: translateY(-100%);
        height: 35vh;
      }

      &:after {
        content: "";
        top: 0;
        height: 100%;
      }

      .content {
        position: relative;
        z-index: 3;
      }

      font-size: 0.9em;

      @include medium {
        font-size: 1em;
      }

      h2 {
        font-weight: 700;
        font-size: 2.75em;
        margin-bottom: 0.5em;
      }

      p {
        line-height: 1.8;
        font-weight: 300;
        font-size: 1.025em;

        & + p {
          margin-top: 1.5em;
        }
      }
    }

    .portrait {
      width: 10em;

      position: absolute;
      right: 0;
      bottom: -21em;
      z-index: 3;

      transition: 0.3s bottom ease-out;

      @include small {
        bottom: -14em;
      }

      @include medium {
        width: 30%;
        max-width: 11.5625em;
        transform: translateX(100%);
        bottom: 5%;
      }

      &.open-drawer {
        bottom: -22em;

        @include medium {
          bottom: 5%;
        }
      }

      img {
        width: 100%;
        position: relative;
      }
    }

    .visual-right {
      position: relative;
      width: 100%;
      height: 20em;
      z-index: 0;

      @include medium {
        position: absolute;
        width: 50vw;
        height: 110%; 
        right: 0;
        top: -10%;
      }

      .photo {
        position: relative;
        width: 100%;
        height: 100%;
        top: 0%;
        overflow: hidden;

        .background {
          position: absolute;
          width: 100%;
          height: 120%;
          bottom: 0;
          background-color: $green;
          background-size: cover;
          background-image: url('../img/forest-splash.jpg');
        }
      }

      .button-circles {
        position: absolute;
        top: -0.5em;
        left: -0.5em;

        @include medium {
          top: auto;
          bottom: -1em;
          left: -0.5em;
        }

        .circle-visual {
          background: $green;
          transform: scale(1);
        }
      }
    }

    .button {
      position: relative;
      background: $green;
      height: auto;

      font-family: $mentone;
      font-size: 1.1em;

      @include medium {
        height: 5em;
        font-size: 1.2em;
      }

      .button-content {
        position: relative;
        z-index: 1;
        height: 5em;
        line-height: 5em;

        cursor: pointer;
        padding-left: 2em;

        @include medium {
          padding: 0;
        }

        &:after {
          content: "";
          width: 0; 
          height: 0; 
          display: inline-block;
          margin-left: 0.75em;

          border-top: 0.3em solid transparent;
          border-bottom: 0.3em solid transparent;
          border-left: 0.3em solid black;
        }
      }

      &:before {
        content: "";
        position: absolute;
        background: $green;
        width: 150%;
        left: -149%;
        top: 0;
        height: 100%;
      }

      .drawer {
        position: absolute;
        z-index: 3;

        width: 100%;

        @include medium {
          width: 20em;
          z-index: 1;

          position: absolute;
          top: 0;
          right: 2em;
        }

        .coat {
          width: 100%;
          height: 100%;
          overflow-y: hidden;
        }

        .drawer-content {
          padding: 2em;
          background: $white;
          width: 100%;

          transform: translateY(-101%);
        }

        .circles {
          width: 1.125em;
          position: absolute;
          right: 1em;
          top: -3em;

          li.circle {
            display: block;
            margin-bottom: 0.75em;

            @include medium {
              display: inline-block;
              margin-bottom: 0;
            }
          }

          @include medium {
            width: 100%;
            top: 1em;
            left: auto;
            left: -3.5em;
          }
        }

        h3 {
          font-weight: 700;
          font-size: 1.25em;
        }

        p {
          margin: 0.5em 0;
          line-height: 1.5;
          font-size: 0.8em;
          font-weight: 400;
          font-family: 'Oxygen', Arial, Helvetica, sans-serif;

          a {
            color: $brown;
          }
        }
      }
    }
  }

  section.contact {
    background: $skyblue-end;

      
    .container {
      padding: 8em 3em;
      font-size: 0.8em;

      @include small {
        padding: 8em 4em;
      }

      @include medium {
        padding: 10em 0;
        font-size: 1em;
      }

      h2 {
        font-size: 3.5em;
        font-weight: 700;
        margin-bottom: 1em;
      }

      li {
        color: $brown;
        margin-bottom: 2.5em;

        @include medium {
          width: 50%;
          float: left;
          margin-bottom: 0;
        }

        p.subtitle {
          font-size: 1.25em;
          font-weight: 700;
          margin: 0 0 0.5em;
        }

        a {
          font-family: $mentone;
          color: $brown;
          text-decoration: none;
          font-size: 2.1em;

          &:hover {
            text-decoration: underline;
          }
        }

        .email a {
          font-size: 1.8em;
        }
      }
    }
  }
}
