footer {
  padding: 2.5em 1.5em;
  color: $footer-brown;

  @include medium {
    padding: 4em 0 3em;
  }

  h3 {
    font-size: 1.75em;

    @include medium {
      float: left;
    }
  } 

  p {
    margin: 0;

    font-family: $mentone;
    line-height: 1.75em;

    @include medium {
      float: right;
    }
  }
}
