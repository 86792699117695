* {
  box-sizing: border-box;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

html {
  background: $brown;
  height: 100%;

  font-family: 'Oxygen', 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 16px;
}

body, html {
	min-height: 100%;
}

.container {
  position: relative;
  width: 100%;
  max-width: 49em;
  margin: 0 auto;

  @include medium {
    width: 90%;
  }
}

h1, h2, h3 {
  margin: 0;
  padding: 0;

  font-family: 'Butler', 'Bookman', 'Georgia', 'Times New Roman', Times, serif;
  font-weight: 900;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  

  &.circles {
    text-align: left;
    font-size: 0.85em;

    @include medium {
      font-size: 1em;
    }

    .row {
      height: 1.125em;
      margin-bottom: 1em;

      ul {
        height: 1.25em;

        & > li {
          float: left;
          margin-right: 1em;
        }
      }
    }

    &.inverted {
      .row ul > li {
        float: right;
        margin-right: 0;
        margin-left: 1em;
      }
    }

    &.small {
      .row {
        height: 1em;
        margin-bottom: 0.5em;

        ul {
          height: 1em;

          & > li {
            margin-right: 0.625em;
            width: 1em;
            height: 1em;
          }
        }
      }
    }
  }
}

.circle {
  position: relative;
  display: block;
  width: 1.125em;
  height: 1.125em;

  .circle-visual {
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    border-radius: 100%;
    background: $white;

    transform-origin: 50% 50%;
    transform: scale(0); 
  }
}