// Defining values
$small-up-width: 22.563em;
$medium-width: 40em;
$medium-up-width: 40.063em;
$large-up-width: 64.063em;

@mixin medium-down {
  @media only screen and (max-width: $medium-width) {
    @content;
  }
}

@mixin small {
  @media only screen and (min-width: $small-up-width) {
    @content;
  }
}

@mixin medium {
  @media only screen and (min-width: $medium-up-width) {
    @content;
  }
}

@mixin large {
  @media only screen and (min-width: $large-up-width) {
    @content;
  }
}

.medium-up {
  display: none !important;

  @include medium {
    display: block !important;
  }
}