@font-face {
  font-family: 'Butler';
  src: url('../fonts/butler_bold.otf') format('opentype');
  src: url('../fonts/butler_bold-webfont.woff2') format('woff2'),
       url('../fonts/butler_bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Butler';
  src: url('../fonts/butler_extrabold.otf') format('opentype');
  src: url('../fonts/butler_extrabold-webfont.woff2') format('woff2'),
       url('../fonts/butler_extrabold-webfont.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Mentone';
  src: url('../fonts/mentone.otf') format('opentype');
  src: url('../fonts/mentone-webfont.woff2') format('woff2'),
       url('../fonts/mentone-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}