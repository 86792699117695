nav {
  position: absolute;
  top: 0;
  right: 1em;

  @include medium {
    right: 0;
  }

  a, div {
    display: inline-block; 
  }

  a {
    font-family: $mentone;
    font-size: 1.4em;
    color: $skyblue;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .dot {
    width: 0.5em;
    margin: 0 1em;

    &:before {
      content: "";
      display: block;
      width: 0.5em;
      height: 0.5em;
      border-radius: 100%;
      background: $skyblue;

      position: relative;
      top: 0.1em;
    }
  }
}
